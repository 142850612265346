import Vue from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import "@/assets/css/all.min.css"
import "@/assets/css/line-awesome.min.css"
import "@/assets/css/vendor/nice-select.css"
import "@/assets/css/vendor/animate.min.css"
import "@/assets/css/vendor/lightcase.css"
import "@/assets/css/vendor/slick.css"
import "@/assets/sass/main.sass"


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
