import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/recover-password', // TODO
    name: 'RecoverPassword',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/games',
    name: 'Games',
    component: () => import('../views/Games.vue')
  },
  {
    path: '/games/:id', // TODO
    name: 'Game',
    component: () => import('../views/Game.vue')
  },
  {
    path: '/users/:id', // TODO
    name: 'User',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/contact', // TODO
    name: 'Contact',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/users/winners', // TODO
    name: 'Winners',
    component: () => import('../views/Login.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
